<template>
  <div>
    <form-wizard
      color="#CBA052"
      :title="null"
      :subtitle="null"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="steps-transparent mb-3"
      @on-complete="formSubmitted"
    >
      <!-- account detail tab -->
      <tab-content title="Customer Details" icon="feather icon-user">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">Customer Details</h5>
          </b-col>
        </b-row>
        <customer-info @selection="customerId($event)" />
      </tab-content>

      <!-- personal details -->
      <tab-content title="Products" icon="feather icon-file-text">
        <product-list :customer="customer" v-if="customer > 0" />
      </tab-content>

      <!-- address -->
      <tab-content title="Services" icon="feather icon-map-pin">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">Services</h5>
          </b-col>
        </b-row>
      </tab-content>

      <!-- social link -->
      <tab-content title="Summary" icon="feather icon-link">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">Summary</h5>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ref } from "@vue/composition-api";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { BRow, BCol, BFormGroup, BFormInput } from "bootstrap-vue";
import CustomerInfo from "./customers/CustomerInfo.vue";
import ProductList from "./products/ProductList.vue";

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    CustomerInfo,
    ProductList,
  },
  methods: {
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Form Submitted",
          icon: "EditIcon",
          variant: "success",
        },
      });
    },
    customerId(id) {
      this.dataCustomer(id);
    },
  },
  setup() {
    const customer = ref(null);
    return {
      dataCustomer: (id) => {
        customer.value = id;
      },
      customer,
    };
  },
};
</script>
