<template>
  <div>
    <b-row>
      <b-col cols="12" xl="9" lg="8" md="7">
        <validation-observer ref="simpleRules">
          <b-form>
            <b-card no-body>
              <b-card-header class="pb-50">
                <h5>Create Sales</h5>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col cols="12" md="4" class="mb-md-0 mb-2">
                    <label>Employee</label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Employee"
                    >
                      <v-select
                        v-model="model.id_employe"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="roleOptions"
                        :reduce="(val) => val.value"
                        :clearable="false"
                        input-id="user-status"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" class="mb-md-0 mb-2">
                    <label>Products</label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Product"
                    >
                      <v-select
                        v-model="model.id_product"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="statusOptions"
                        :reduce="(val) => val.value"
                        :clearable="false"
                        input-id="user-status"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="2" class="mb-md-0 mb-2">
                    <label>QTY</label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required|integer|min:1"
                      name="QTY"
                    >
                      <b-form-input
                        v-model="model.qty"
                        :state="errors.length > 0 ? false : null"
                        placeholder=""
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="2" class="mb-md-0 mb-2">
                    <label></label>
                    <b-button
                      variant="primary"
                      block
                      @click.prevent="validationForm"
                    >
                      Add
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>
      <b-col cols="12" md="2" xl="3" lg="4">
        <resumen />
      </b-col>
    </b-row>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table>
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">@{{ data.item.username }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{
              data.item.role
            }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status === true ? "Active" : "Inactive" }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{
                name: 'apps-customers-edit',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item>
              <feather-icon icon="CheckSquareIcon" />
              <span class="align-middle ml-50" @click="updateStatus(data.item)"
                >Update Status</span
              >
            </b-dropdown-item>
            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50" @click="confirmDelete(data.item)"
                >Delete</span
              >
            </b-dropdown-item>
            <b-dropdown-item
              :to="{
                name: 'apps-customers-detail',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Detail</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BCardHeader,
  BCardBody,
  BForm,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import vSelect from "vue-select";
import router from "@/router";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import UsersListFilters from "./UsersListFilters.vue";
import useUsersList from "./useUsersList";
import userStoreModule from "../userStoreModule";
import Resumen from "../Resumen.vue";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    UsersListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BForm,

    vSelect,
    Resumen,
    BCardHeader,
    BCardBody,

    ValidationProvider,
    ValidationObserver,
  },

  methods: {
    // confirm texrt
    confirmDelete(data) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteCustomer(data);
          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: "Your file has been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.createSale();
          console.log("validando");
        }
      });
    },
  },
  props: {
    customer: {
      default: 0,
    },
  },
  setup(props) {
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const isAddNewUserSidebarActive = ref(false);

    const toast = useToast();

    const {
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      dataItemStatus,
      dataItemCountries,
      fetchUsers,
    } = useUsersList();

    const roleOptions = ref([]);

    const statusOptions = ref([]);

    const userData = ref(null);

    const employees = [];

    const product = [];

    const { customer } = props;

    const model = ref({
      id_sale: 1,
      id_employe: "",
      id_product: "",
      price: "",
      percentage_comission: "",
      status: true,
    });

    store
      .dispatch("app-user/fetchHelperSales")
      .then((response) => {
        const { employee, products } = response.data.data;
        employee.map((data, item) => {
          employees.push({
            label: `${data.first_name} ${data.last_name}`,
            value: data.identification_number,
            id: item,
          });
        });

        products.map((data, item) => {
          product.push({
            id: item,
            value: data.id,
            label: data.name,
            price: data.price,
            percentage_comission: data.percentage_comission,
          });
        });

        roleOptions.value = employees;
        statusOptions.value = product;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      model,

      updateStatus: (data) => {
        console.log(data);
        const { id, status, first_name, last_name } = data;
        const isTrueSet =
          status == String(status ? true : false)
            ? status
              ? true
              : false
            : !status
            ? true
            : false;
        store
          .dispatch("app-user/updateCountryStatus", { status: isTrueSet, id })
          .then((response) => {
            toast({
              component: ToastificationContent,
              props: {
                title: `The customer ${first_name} ${last_name} was update status correctly`,
                icon: "AlertTriangleIcon",
                variant: "success",
              },
            });
            refetchData();
          });
      },
      deleteCustomer: (data) => {
        const { id, first_name, last_name } = data;
        store.dispatch("app-user/deleteCountry", { id }).then((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: `The Customer ${first_name} ${last_name} was delete correctly`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          refetchData();
        });
      },
      createSale: () => {
        const result = product.filter(
          (data) => data.value === model.value.id_product
        );
        const { price, percentage_comission } = result[0];
        model.value.price = price;
        model.value.percentage_comission = percentage_comission;
        store
          .dispatch("app-user/addSaleProduct", { model })
          .then((response) => {
            userData.value = response.data;
            toast({
              component: ToastificationContent,
              props: {
                title: `The office ${model.value} was created correctly`,
                icon: "AlertTriangleIcon",
                variant: "success",
              },
            });
            router.push({ name: "apps-customers-list" });
          })
          .catch((error) => {
            if (error.response.status === 404) {
              userData.value = undefined;
            }
          });
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
