import axios from "@axios";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getCustomerSales", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/getOneCustomer/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addCountry(ctx, customerData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/addCustomer", { customerData })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    updateCountry(ctx, queryParams) {
      const id = queryParams.model.value.id;
      delete queryParams.model.value.id;
      return new Promise((resolve, reject) => {
        axios
          .put(`/updateCustomer/${id}`, { params: queryParams.model.value })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateCountryStatus(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const status = { status: queryParams.status };
        axios
          .put(`/updateCustomerStatus/${queryParams.id}`, { params: status })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteCountry(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/deleteCustomer/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchHelpers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getHelpes", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchHelperSales(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getHelperSales", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    addSaleProduct(ctx, saleProductData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/addSaleProduct", { saleProductData })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
