<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="12">
          <b-form-group label="Customer" label-for="i-country">
            <v-select
              v-model="model.selectedCustomer"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="customerOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="user-status"
              :v-on:change="customerData()"
              @option:selected="$emit('selection', model.selectedCustomer)"
            ></v-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="userData.loading">
      <b-row>
        <!-- User Info: Left col -->
        <b-col
          cols="21"
          xl="6"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar size="104px" rounded />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ userData.first_name }} {{ userData.last_name }}
                </h4>
                <span class="card-text">{{ userData.mail }}</span>
              </div>
            </div>
          </div>

          <!-- User Stats -->
          <div class="d-flex align-items-center mt-2">
            <div class="d-flex align-items-center mr-2">
              <b-avatar variant="light-primary" rounded>
                <feather-icon icon="DollarSignIcon" size="18" />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">23.3k</h5>
                <small>Monthly Sales</small>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <b-avatar variant="light-success" rounded>
                <feather-icon icon="TrendingUpIcon" size="18" />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">$99.87k</h5>
                <small>Annual Profit</small>
              </div>
            </div>
          </div>
        </b-col>

        <!-- Right Col: Table -->
        <b-col cols="12" xl="6">
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon icon="UserIcon" class="mr-75" />
                <span class="font-weight-bold">Identification</span>
              </th>
              <td class="pb-50">{{ userData.identification_number }}</td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">Status</span>
              </th>
              <td class="pb-50 text-capitalize">
                <b-badge variant="primary">{{ userData.status }} </b-badge>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="StarIcon" class="mr-75" />
                <span class="font-weight-bold">Role</span>
              </th>
              <td class="pb-50 text-capitalize">Customer</td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="FlagIcon" class="mr-75" />
                <span class="font-weight-bold">City</span>
              </th>
              <td class="pb-50">{{ userData.cityId }}</td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="FlagIcon" class="mr-75" />
                <span class="font-weight-bold">Adress</span>
              </th>
              <td class="pb-50">{{ userData.adress }}</td>
            </tr>
            <tr>
              <th>
                <feather-icon icon="PhoneIcon" class="mr-75" />
                <span class="font-weight-bold">Contact</span>
              </th>
              <td>{{ userData.phone_number }}</td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BBadge,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import userStoreModule from "../userStoreModule";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    vSelect,
    BFormGroup,
    BFormInput,
    BBadge,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const model = ref({
      selectedCustomer: "",
    });
    const customerOptions = ref([]);

    const userData = ref({
      id: "",
      identification_number: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      status: "",
      birth_date: "",
      citiyd: "",
      adress: "",
      loading: false,
    });
    const city = [];

    store
      .dispatch("app-user/fetchHelpers")
      .then((response) => {
        const { customers } = response.data.data;
        customers.map((data, item) => {
          city.push({
            label: `${data.first_name} ${data.last_name}`,
            value: data.id,
            id: item,
          });
        });

        customerOptions.value = city;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });

    const customerData = () => {
      const { selectedCustomer } = model.value;
      if (selectedCustomer !== userData.value.id) {
        store
          .dispatch("app-user/fetchUser", { id: selectedCustomer })
          .then((response) => {
            userData.value = response.data;
            userData.value.loading = true;
          })
          .catch((error) => {
            if (error.response.status === 404) {
              userData.value = undefined;
            }
          });
      }
    };
    return {
      avatarText,

      customerOptions,
      model,
      customerData,
      userData,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
